<script>
import appConfig from "@/app.config";
import { integer } from "@vuelidate/validators";
import $ from "jquery";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  computed: {
    shuffledJawaban() {
      // Shuffle the array of jawaban
      return this.shuffleArray(this.list.jawaban);
    },
  },
  data() {
    return {
      link: process.env.VUE_APP_BACKEND_URL,
      selectedJawabanId: null,
      previewImage: false,
      urlSrc: "",
    };
  },
  props: {
    list: [],
    nomorUrut: integer,
    currentPage: integer,
    selectAnswer: Function,
  },
  emits: ["showImage"],
  mounted() {
    this.selectJawaban = null;
    const self = this;
    $(".pilihan-jawaban img")
      .addClass("image-soal")
      .css("height", "100px")
      .wrap("<div class='container-image-soal'></div");
    // $(".image-soal").after(
    //   `<div
    //     class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
    //   >
    //     <button
    //       class="btn btn-success btn-sm preview-btn"
    //     >
    //       <i class="fa fa-search"></i> Preview
    //     </button>
    //   </div>`
    // );

    $(".image-soal").click(function () {
      self.previewImage = true;
      self.urlSrc = $(this).attr("src");
      self.$emit("showImage", $(this).attr("src"));
    });
  },
  methods: {
    shuffleArray(array) {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    selectAnswerFunction(list, jawaban) {
      if (typeof this.selectAnswer === "function") {
        this.selectAnswer(list, jawaban);
      }
    },
    getTextWidth(text) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = window.getComputedStyle(document.body).font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    calculateButtonWidth(text) {
      // Get the width of the text in pixels
      const textWidth = this.getTextWidth(text);
      // Add padding and margin to the calculated width
      const totalWidth = textWidth + 20; // Adjust as needed
      // Return the width as a string with 'px' unit
      return totalWidth + "px";
    },
    preview(url) {
      this.$emit("showImage", url);
    },
  },
};
</script>

<template>
  <div class="w-100 text-center">
    <div
      class="container-image"
      style="width: 30%; margin: auto"
      v-if="list.soal_image"
    >
      <img v-if="list.soal_image" :src="link + list.soal_image" class="image" />
      <div
        class="middle d-flex flex-column gap-2 align-items-center justify-content-center"
      >
        <button
          class="btn btn-success btn-sm"
          @click="preview(link + list.soal_image)"
        >
          <i class="fa fa-search"></i> Preview
        </button>
      </div>
    </div>
  </div>
  <div v-if="list.soal_text !== null" class="card mb-2">
    <div class="card-body bg-light" style="border-radius: 5px">
      {{ list.soal_text }}
    </div>
  </div>
  <div class="soal mt-3 px-3">{{ nomorUrut }}. {{ list.pertanyaan }}</div>
  <div class="pilihan-ganda row mx-4">
    <!-- <div
      class="col-md-6"
      v-for="jawaban in shuffledJawaban"
      :key="jawaban.idjawaban"
    >
      <div class="d-flex">
        <button
          class="mt-3 btn btn-block flex-grow-1"
          :class="[jawaban.selected ? 'btn-primary' : 'btn-secondary']"
          @click="selectAnswerFunction(list, jawaban)"
        >
          {{ jawaban.jawaban }}
        </button>
      </div>
    </div> -->

    <div
      class="col-md-6 mt-5"
      v-for="jawaban in shuffledJawaban"
      :key="jawaban.idjawaban"
    >
      <div
        class="d-flex justify-content-center align-items-center pilihan-jawaban"
        :class="[jawaban.selected ? 'bg-primary' : 'bg-secondary']"
        @click="selectAnswerFunction(list, jawaban)"
      >
        <div v-html="jawaban.jawaban"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.border-none {
  border-style: hidden;
}

.selected {
  background-color: #00f;
  /* Change the background color for selected buttons */
  color: #fff;
  /* Change the text color for selected buttons */
}
.pilihan-jawaban {
  height: 150px;
  color: #fff;
  text-align: center;
}
</style>
